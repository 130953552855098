import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
} from "@mui/material";

const DataTable = ({
  columns,
  data,
  catchChangePageEvent,
  loading,
  havePaging = true,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [displayData, setDisplayData] = React.useState([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    catchChangePageEvent(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    catchChangePageEvent(0, +event.target.value);
  };

  React.useEffect(() => {
    if (!havePaging) {
      setDisplayData(data);
    } else {
      setDisplayData(data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }
  }, [data, page, rowsPerPage]);

  const TruncatedString = ( fullString) => {
    const truncatedString = fullString.length > 10
      ? `${fullString.slice(0, 10)}...${fullString.slice(-4)}`
      : fullString;
  
    return truncatedString;
  };

  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden" }}
      className="!bg-transparent !shadow-lg !shadow-[#edff20] relative"
    >
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-80 z-10 flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
      <TableContainer
        sx={{ maxHeight: havePaging ? 600 : "none" }}
        className="bg-transparent"
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          className="h-full bg-transparent"
        >
          <TableHead className="bg-black">
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className="!bg-black !text-white"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {displayData?.map((row, index) => {
              return (
                <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="!text-white"
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {havePaging && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="!bg-transparent !text-white"
        />
      )}
    </Paper>
  );
};

export default DataTable;
