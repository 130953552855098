import config from '../config';
import Validators from '../pages/Validators';
import ValidatorDetail from '../pages/ValidatorDetail';
import Blocks from '../pages/Blocks';
import BlockDetail from '../pages/BlockDetail';
import Transactions from '../pages/Transactions';
import TransactionDetail from '../pages/TransactionDetail';
// import TransactionDetail from '~/pages/TransactionDetail/index.tsx';

const publicRoutes = [
    { path: config.routes.validators, component: Validators },
    { path: config.routes.validatorDetail, component: ValidatorDetail },
    { path: config.routes.blocks, component: Blocks },
    { path: config.routes.blockDetail, component: BlockDetail },
    { path: config.routes.transactions, component: Transactions },
    { path: config.routes.transactionDetail, component: TransactionDetail },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
