import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaUser } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import { MdWork } from "react-icons/md";
import namadaIcon from "../../assets/namada-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../../config";
import { InputBase, Paper } from "@mui/material";

const Layout = ({ children }) => {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");
  const [listNav, setListNav] = useState([
    {
      icon: <FaUser />,
      title: "Validator",
      isActive: true,
      link: config.routes.validators,
    },
    {
      icon: <IoIosPaper />,
      title: "Blocks",
      isActive: false,
      link: config.routes.blocks,
    },
    {
      icon: <MdWork />,
      title: "Transactions",
      isActive: false,
      link: config.routes.transactions,
    },
  ]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/validators");
      return;
    }
    const updatedListNav = listNav.map((navItem) => {
      if (
        navItem.link === location.pathname ||
        location.pathname.includes(navItem.link.slice(0, -1))
      ) {
        navItem.isActive = true;
      } else {
        navItem.isActive = false;
      }
      return navItem;
    });

    setListNav(updatedListNav);
  }, [location.pathname]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEnterKeyPress = (e) => {
    const searchData = inputValue.trim();
    if (searchData.length > 0) {
      if (searchData.length === 64) {
        navigate(`/transaction/${searchData}`);
      } else if (searchData.length === 40) {
        navigate(`/validator/${searchData}`);
      }
      else {
        navigate(`/block/${searchData}`);
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full h-[75px] bg-bodyColor text-white z-50 flex items-start justify-between p-4 rounded-xl">
        <div className="flex flex-col">
          <b>shielded-expedition.234567890</b>
          <a
            href="https://rpc.thunamada.xyz"
            rel="noreferrer"
            target="_blank"
            className="link"
          >
            https://rpc.thunamada.xyz
          </a>
        </div>
        <Paper
          sx={{
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 600,
            margin: "10px 0",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="height/ tx/ validator"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(e) => e.key === "Enter" && handleEnterKeyPress()}
          />
        </Paper>
      </div>
      <div className="w-full h-full bg-transparent text-white z-50 flex items-start justify-between p-4 lgl:p-0">
        <div className="w-16 h-96 bg-transparent flex flex-col gap-4">
          {/* ======= Home Icon start */}
          <div className="w-full h-20 bg-bodyColor rounded-3xl flex justify-center items-center group mt-2">
            <img src={namadaIcon} width={"50%"}></img>
          </div>
          {/* ======= Home Icon End */}

          {/* ======= Other Icons Start */}
          <div className="w-full h-80 bg-bodyColor rounded-3xl flex flex-col items-center justify-between py-6">
            {listNav?.map((navItem) => (
              <span
                key={navItem.link}
                className={`${
                  navItem.isActive ? "!text-designColor" : "text-textColor"
                } w-full h-6 text-xl flex items-center justify-center hover:text-designColor duration-300 cursor-pointer relative group`}
              >
                <Link to={navItem.link}>{navItem.icon}</Link>
                <span className="text-black font-medium text-xs uppercase bg-designColor px-4 py-[1px] rounded-xl absolute left-0 translate-x-8 group-hover:translate-x-12 opacity-0 group-hover:opacity-100 transition-all duration-300 z-20">
                  {navItem.title}
                </span>
              </span>
            ))}
          </div>
          {/* ======= Other Icons End */}
        </div>
        {/* ================= Left Icons Start here ====================== */}
        <div className="w-full lgl:w-[94%] h-full flex flex-col gap-6 mt-2 items-center">
          <div className="w-full h-[90%] bg-bodyColor rounded-2xl flex justify-center items-center">
            <div className="w-full h-[96%] flex justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-[#646464]">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-[90%]"
              >
                {children}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
