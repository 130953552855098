import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { Link } from "react-router-dom";
import config from "../../config";
import { formatDistanceToNow } from "date-fns";
import { useDebounce, useInterval } from "../../hooks";
import { fetchBlocks } from "../../api/store_api.ts";
import { InputBase, Paper, Tooltip } from "@mui/material";
import DataTable from "../../components/Table/index.tsx";

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateValidatorDetailPath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

const columns = [
  { id: "no", label: "No" },
  {
    id: "height",
    label: "HEIGHT",
    format: (height) => (
      <Link
        className="link"
        to={generateBlockDetailPath(config.routes.blockDetail, height)}
      >
        {height}
      </Link>
    ),
  },
  {
    id: "hash",
    label: "HASH",
    format: (hash) => (
      <Tooltip placement="bottom" title={hash}>
        <span>
          {hash.length > 10 ? `${hash.slice(0, 5)}...${hash.slice(-5)}` : hash}
        </span>
      </Tooltip>
    ),
  },
  { id: "txs", label: "TXS" },
  {
    id: "proposer",
    label: "PROPOSER",
    format: (height) => (
      <Link
        className="link"
        to={generateValidatorDetailPath(config.routes.validatorDetail, height)}
      >
        {height}
      </Link>
    ),
  },
  {
    id: "time",
    label: "TIME",
    format: (time) => {
      return time ? (
        <Tooltip placement="bottom" title={time}>
          <p>{formatDistanceToNow(time || "", { addSuffix: true })}</p>
        </Tooltip>
      ) : (
        <></>
      );
    },
  },
];

const Block = () => {
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debounced = useDebounce(searchValue, 500);

  const catchChangePageEvent = (page, rowsPerPage) => {
    setIsFilter(page > 0);
  };

  var isFetchingData = false;
  const fetchData = async () => {
    try {
      if (isFetchingData) {
        return;
      }

      isFetchingData = true;
      const response = await fetchBlocks(1, 20);
      if (response) {
        const tempData = response.map((data) => {
          return {
            key: data.height,
            ...data,
          };
        });

        setOriginData(tempData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      isFetchingData = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  useInterval(() => {
    if (!isFilter) {
      fetchData();
    }
  }, 5000);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isFilter) {
      setData(originData);
    }
  }, [originData]);

  useEffect(() => {
    filterDataBySearchInput(debounced);
  }, [debounced]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();

    const newData = !searchInput
      ? originData
      : originData.filter(
          ({ height, hash, proposer }) =>
            height.toLowerCase().includes(normalizedSearchInput) ||
            hash.toLowerCase().includes(normalizedSearchInput) ||
            proposer.toLowerCase().includes(normalizedSearchInput)
        );

    setData(newData);
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;

    if (searchValue.startsWith(" ")) {
      setIsFilter(false);
      return;
    } else if (searchValue === "") {
      setSearchValue(searchValue);
      setIsFilter(false);
    } else {
      setSearchValue(searchValue);
      setIsFilter(true);
    }
  };

  return (
    <section id="block">
      <Title title="Block" subTitle="Lastest 20 blocks" />
      <Paper
        sx={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 600,
          margin: "10px 0",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search by height/hash/proposer"
          onChange={handleChange}
          onKeyDown={(e) =>
            e.key === "Enter" &&
            (() => {
              return;
            })
          }
        />
      </Paper>
      <DataTable
        columns={columns}
        data={data}
        catchChangePageEvent={catchChangePageEvent}
        loading={loading}
      />
    </section>
  );
};

export default Block;
