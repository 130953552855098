const routes = {
    home: '/',
    validators: '/validators',
    validatorDetail: '/validator/:address',
    blocks: '/blocks',
    blockDetail: '/block/:height',
    transactions: '/transactions',
    transactionDetail: '/transaction/:tx',
};

export default routes;
