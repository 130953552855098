import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { Link } from "react-router-dom";
import config from "../../config/index.js";
import { useDebounce, useInterval } from "../../hooks";
import { fetchBlockByHash, fetchTransactions } from "../../api/store_api.ts";
import { InputBase, Paper, Tooltip } from "@mui/material";
import DataTable from "../../components/Table/index.tsx";
import { formatDistanceToNow } from "date-fns";

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

function generateTransactionDetailPath(template, tx) {
  let path = template;
  path = path.replace(`:tx`, tx);
  return path;
}

const columns = [
  { id: "no", label: "No" },
  {
    id: "tx",
    label: "TX",
    minWidth: 100,
    format: (tx) => (
      <Link
        className="link"
        to={generateTransactionDetailPath(config.routes.transactionDetail, tx)}
      >
        {tx}
      </Link>
    ),
  },
  {
    id: "height",
    label: "HEIGHT",
    format: (height) => (
      <Link
        className="link"
        to={generateBlockDetailPath(config.routes.blockDetail, height)}
      >
        {height}
      </Link>
    ),
  },
  { id: "type", label: "TYPE" },
  { id: "shielded", label: "SHIELDED" },
  {
    id: "status",
    label: "STATUS",
    format: (status) => (
      <p className={status === "Success" ? "text-green-500" : "text-red-500"}>
        {status}
      </p>
    ),
  },
  {
    id: "time",
    label: "TIME",
    format: (time) => {
      return time ? (
        <Tooltip placement="bottom" title={time}>
          <p>{formatDistanceToNow(time || "", { addSuffix: true })}</p>
        </Tooltip>
      ) : (
        <></>
      );
    },
  },
];

const Transactions = () => {
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debounced = useDebounce(searchValue, 500);
  const [isFilter, setIsFilter] = useState(false);
  const blocks = new Map();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const catchChangePageEvent = (page, rowsPerPage) => {
    setPage(page);
    setRowsPerPage(rowsPerPage);
    setIsFilter(page > 0);
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  var isFetchingData = false;
  const fetchData = async () => {
    try {
      if (isFetchingData) {
        return;
      }

      isFetchingData = true;
      const response = await fetchTransactions(1, 20);
      if (response) {
        const tempData = response.map((data) => {
          return {
            key: data.tx,
            ...data,
          };
        });

        if (originData.length === 0) {
          setOriginData(tempData);
        } else {
          const itemToAdds = tempData.filter(
            (temp) => !originData.some((origin) => origin.tx === temp.tx)
          );
          if (itemToAdds?.length > 0) {
            setOriginData((prevData) => itemToAdds.concat(prevData));
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      isFetchingData = false;
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (!isFilter && !originData.some((tran) => !tran.height)) {
      if (originData.length > 20) {
        const tmp = originData.slice(0, 20);
        setData(tmp);
        setOriginData(tmp);
      } else {
        setData(originData);
      }
    }
  }, [originData]);

  useInterval(() => {
    if (!isFilter) {
      fetchData();
    }
  }, 5000);

  useEffect(() => {
    fetchData();
  }, []);

  const getHeightOfTransaction = async () => {
    try {
      // setLoading(true);

      const updatedTxs = [];

      for (let index = 0; index < originData.length; index++) {
        const tx = originData[index];
        if (tx.height) {
          updatedTxs.push({
            ...tx,
            no: index + 1,
          });
          continue;
        }
        const block = blocks.get(tx.blockId);

        if (!block) {
          const res = await fetchBlockByHash(tx.blockId);
          blocks.set(tx.blockId, {
            blockId: tx.blockId,
            height: res.header.height,
            time: res.header.time,
          });

          const updatedTx = {
            ...tx,
            height: blocks.get(tx.blockId).height,
            time: blocks.get(tx.blockId).time,
            no: index + 1,
          };

          updatedTxs.push(updatedTx);
        } else {
          const updatedTx = {
            ...tx,
            height: block.height,
            time: block.time,
            no: index + 1,
          };

          updatedTxs.push(updatedTx);
        }
      }

      setOriginData(updatedTxs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (originData.some((tran) => !tran.height)) {
      getHeightOfTransaction();
    }
  }, [originData, page, rowsPerPage]);

  useEffect(() => {
    filterDataBySearchInput(debounced);
  }, [debounced]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput?.toLowerCase();

    const newData = !searchInput
      ? originData
      : originData.filter(
          ({ tx, height }) =>
            height.toLowerCase().includes(normalizedSearchInput) ||
            tx.toLowerCase().includes(normalizedSearchInput)
        );

    setData(newData);
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;

    if (searchValue.startsWith(" ")) {
      setIsFilter(false);
      return;
    } else if (searchValue === "") {
      setSearchValue(searchValue);
      setIsFilter(false);
    } else {
      setSearchValue(searchValue);
      setIsFilter(true);
    }
  };

  return (
    <div>
      <Title title="Transactions" subTitle="Lastest 20 transaction" />
      <Paper
        sx={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 600,
          margin: "10px 0",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search by tx/height"
          onChange={handleChange}
        />
      </Paper>
      <DataTable
        columns={columns}
        data={data}
        catchChangePageEvent={catchChangePageEvent}
        loading={loading}
      />
    </div>
  );
};

export default Transactions;
