import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import { fetchBlockDetail } from "../../api/store_api.ts";
import { CircularProgress } from "@mui/material";
import DataTable from "../../components/Table/index.tsx";

function generateTransDetailPath(template, tx) {
  let path = template;
  path = path.replace(`:tx`, tx);
  return path;
}

function generatePath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

const columns = [
  { id: "tx_type", label: "Transaction Type" },
  {
    id: "hash_id",
    label: "Hash Id",
    format: (hash) => (
      <Link
        className="link"
        to={generateTransDetailPath(config.routes.transactionDetail, hash)}
      >
        {hash}
      </Link>
    ),
  },
];

const BlockDetail = () => {
  const [loading, setLoading] = useState(false);
  const [blockDetail, setBlockDetail] = useState({
    header: {
      height: "",
      proposer_address: "",
      time: "",
    },
    block_id: "",
    tx_hashes: [{ tx_type: "", hash_id: "" }],
  });
  let { height } = useParams();

  const getBlockDetail = async () => {
    try {
      setLoading(true);
      const response = await fetchBlockDetail(height);
      setBlockDetail(response || {});
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlockDetail();
  }, [height]);

  return (
    <section id="block">
      <Title title="Block detail" />
      {loading ? (
        <>
          <div className="spin-wrapper flex justify-center my-2">
            <CircularProgress />
          </div>
        </>
      ) : !blockDetail?.block_id ? (
        <>Data not found</>
      ) : (
        <>
          <div className="w-full p-6">
            <ul className="flex flex-col gap-1">
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Hash:</span>
                {blockDetail?.block_id}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Proposer:</span>
                <Link
                  className="link"
                  to={generatePath(
                    config.routes.validatorDetail,
                    blockDetail?.header.proposer_address
                  )}
                >
                  {blockDetail?.header.proposer_address}
                </Link>
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Height:</span>
                {blockDetail?.header.height}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">No of Txs:</span>
                {blockDetail?.tx_hashes.length}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Time:</span>
                {blockDetail?.header.time}
              </li>
            </ul>
          </div>
          <DataTable
            columns={columns}
            data={blockDetail?.tx_hashes}
            loading={loading}
            havePaging={false}
          />
        </>
      )}
    </section>
  );
};

export default BlockDetail;
