import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { Link, useParams } from "react-router-dom";
import { fetchLatestSignatures, fetchValidator } from "../../api/store_api.ts";
import { CircularProgress, Tooltip } from "@mui/material";
import { useInterval } from "../../hooks";
import { AnimatePresence, motion } from "framer-motion";
import config from "../../config/index.js";

function generateBlockDetailPath(template, height) {
  let path = template;
  path = path.replace(`:height`, height);
  return path;
}

const ValidatorDetail = () => {
  const [listBlocks, setListBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validatorDetail, setValidatorDetail] = useState({
    validator: "",
    uptime: "",
    votingPower: "",
    participation: "",
    commitSignatures: 0,
    moniker: "",
  });
  let { address } = useParams();

  var isFetchingBlock = false;
  const getBlocks = async () => {
    try {
      if (isFetchingBlock) {
        return;
      }
      isFetchingBlock = true;

      const response = await fetchLatestSignatures(address);

      if (listBlocks.length === 0) {
        setListBlocks(response);
      } else {
        const itemToAdds = response.filter(
          (temp) =>
            !listBlocks.some(
              (origin) => origin.block_number === temp.block_number
            )
        );
        if (itemToAdds?.length > 0) {
          setListBlocks((prevData) => itemToAdds.concat(prevData));
        }
      }
    } catch (error) {
    } finally {
      isFetchingBlock = false;
      setLoading(false);
    }
  };
  const getTransDetail = async () => {
    const response = await fetchValidator(address);
    setValidatorDetail(response);
  };
  useEffect(() => {
    getTransDetail();
  }, [address]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useInterval(() => {
    getBlocks();
  }, 5000);

  useEffect(() => {
    getBlocks();
  }, []);

  useEffect(() => {
    if (listBlocks.length > 100) {
      setListBlocks((prev) => prev.slice(0, 100));
    }
  }, [listBlocks]);

  return (
    <section id="validator">
      <Title title="Validator detail" />
      {loading ? (
        <>
          <div className="spin-wrapper flex justify-center my-2">
            <CircularProgress />
          </div>
        </>
      ) : !validatorDetail?.moniker ? (
        <>Data not found</>
      ) : (
        <>
          <div className="w-full p-6">
            <ul className="flex flex-col gap-1">
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Moniker:</span>
                {validatorDetail.moniker}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Address:</span>
                {validatorDetail.validator}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Voting power:</span>
                {validatorDetail.votingPower}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Commit signatures:</span>
                {validatorDetail.commitSignatures}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Participation:</span>
                {validatorDetail.participation}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Up time:</span>
                {validatorDetail.uptime}
              </li>
            </ul>
          </div>
          <h3 style={{ fontWeight: "bold" }}>Latest Signatures</h3>
          {listBlocks.length === 0 ? (
            <div className="spin-wrapper flex justify-center my-2">
              <CircularProgress />
            </div>
          ) : (
            <AnimatePresence>
              <div className="flex gap-1 flex-wrap">
                {listBlocks.map((block, index) => {
                  const blockClass = block.sign_status
                    ? "block-active"
                    : "block-inactive";
                  const title = (
                    <>
                      <p>Block: {block.block_number}</p>
                      <p>Signed: {block.sign_status.toString()}</p>
                    </>
                  );
                  return (
                    <motion.div
                      layout
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ opacity: 0 }}
                      key={block.block_number}
                    >
                      <Tooltip placement="top" title={title}>
                        <Link
                          to={generateBlockDetailPath(
                            config.routes.blockDetail,
                            block.block_number
                          )}
                        >
                          <div className={`block ${blockClass} `}></div>
                        </Link>
                      </Tooltip>
                    </motion.div>
                  );
                })}
              </div>
            </AnimatePresence>
          )}
        </>
      )}
    </section>
  );
};

export default ValidatorDetail;
