import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import DataTable from "../../components/Table/index.tsx";
import { useDebounce } from "../../hooks";
import {
  fetchValidatorCommitSignatures,
  fetchValidatorUptime,
  fetchValidatorsWithDetails,
} from "../../api/store_api.ts";
import { Link } from "react-router-dom";
import config from "../../config/index.js";
import { InputBase, Paper } from "@mui/material";

function generatePath(template, address) {
  let path = template;
  path = path.replace(`:address`, address);
  return path;
}

const columns = [
  {
    id: "validator",
    label: "VALIDATOR",
    minWidth: 170,
    format: (address) => (
      <Link
        className="link"
        to={generatePath(config.routes.validatorDetail, address)}
      >
        {address}
      </Link>
    ),
  },
  { id: "moniker", label: "MONIKER", minWidth: 100 },
  { id: "upTime", label: "UP TIME" },
  { id: "votingPower", label: "VOTING POWER" },
  { id: "commitSignatures", label: "COMMIT SIGNATURES" },
  { id: "participation", label: "PARTICIPATION" },
];

const Validators = () => {
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debounced = useDebounce(searchValue, 500);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const catchChangePageEvent = (page, rowsPerPage) => {
    setPage(page);
    setRowsPerPage(rowsPerPage);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchValidatorsWithDetails();
      if (response) {
        const tempData = response.map((data, index) => {
          return {
            key: index,
            ...data,
          };
        });
        setOriginData(tempData);
        setData(tempData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUptimeAndSignatures = async () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const itemsForCurrentPage = data.slice(startIndex, endIndex);
    try {
      setLoading(true);
      const validatorsWithDetails = await Promise.all(
        itemsForCurrentPage.map(async (validator) => {
          if (!validator.upTime && !validator.commitSignatures) {
            const upTime = await fetchValidatorUptime(
              validator.validator,
              0,
              1000
            ).then((data) => (data.uptime * 100).toFixed(2) + "%");

            const commitSignatures = await fetchValidatorCommitSignatures(
              validator.validator
            );

            return {
              ...validator,
              upTime,
              commitSignatures,
            };
          } else {
            return validator;
          }
        })
      );

      const updatedData = data.map((item, index) => {
        if (index >= startIndex && index < endIndex) {
          return validatorsWithDetails[index - startIndex];
        }
        return item;
      });

      setData(updatedData);

      const updatedOriginData = originData.map((originItem) => {
        const detailIndex = validatorsWithDetails.findIndex(
          (detailItem) => detailItem.validator === originItem.validator
        );
        return detailIndex !== -1
          ? validatorsWithDetails[detailIndex]
          : originItem;
      });

      setOriginData(updatedOriginData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const itemsForCurrentPage = data.slice(startIndex, endIndex);
    if (
      itemsForCurrentPage.some(
        (validator) => !validator.upTime && !validator.commitSignatures
      )
    ) {
      getUptimeAndSignatures();
    }
  }, [data, page, rowsPerPage]);

  useEffect(() => {
    filterDataBySearchInput(debounced);
  }, [debounced]);

  const filterDataBySearchInput = (searchInput) => {
    const normalizedSearchInput = searchInput.toLowerCase();

    const newData = !searchInput
      ? originData
      : originData.filter(
          ({ validator, moniker }) =>
            validator?.toLowerCase()?.includes(normalizedSearchInput) ||
            moniker?.toLowerCase()?.includes(normalizedSearchInput)
        );

    setData(newData);
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;

    if (searchValue.startsWith(" ")) {
      return;
    }
    setSearchValue(searchValue);
  };

  return (
    <section id="validators" className="w-full h-[95%]">
      <Title title="Validators" />
      <Paper
        sx={{
          padding: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 600,
          margin: "10px 0",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search by validator/moniker"
          onChange={handleChange}
        />
      </Paper>
      <DataTable
        columns={columns}
        data={data}
        catchChangePageEvent={catchChangePageEvent}
        loading={loading}
      />
    </section>
  );
};

export default Validators;
