import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import {
  fetchBlockByHash,
  fetchTransactionDetail,
} from "../../api/store_api.ts";
import { CircularProgress } from "@mui/material";
import RawData from "./RawData/RawData";

const TransactionDetail = () => {
  const [loading, setLoading] = useState(false);
  const [tranDetail, setTranDetail] = useState({
    hash: "",
    blockId: "",
    gasWanted: "0",
    gasUsed: "0",
    returnCode: 0,
    fee: 0,
    txType: "",
    data: "",
    tx: {
      Transfer: {
        shielded: "",
      },
    },
  });
  let { tx } = useParams();
  const [block, setBlock] = useState({
    chainId: "",
    height: "",
    hash: "",
    time: "",
    proposer: "",
  });

  const getTranDetail = async () => {
    try {
      setLoading(true);
      const resTx = await fetchTransactionDetail(tx);
      const txData = {
        hash: resTx.hash,
        blockId: resTx.block_id,
        gasWanted: "0",
        gasUsed: "0",
        returnCode: resTx.return_code,
        fee: resTx.fee_amount_per_gas_unit ? resTx.fee_amount_per_gas_unit : 0,
        data: resTx.data,
        tx:
          resTx.tx_type === "Decrypted" && resTx.tx && resTx.tx.Ibc
            ? {
                typeUrl: resTx.tx.Ibc.Any.type_url,
                value: [...resTx.tx.Ibc.Any.value.slice(0, 10), "..."],
              }
            : { ...resTx.tx },
        txType: resTx.tx_type,
      };
      setTranDetail(txData);
      getBlock(resTx.block_id);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getBlock = async (blockId) => {
    const resBlock = await fetchBlockByHash(blockId);

    const block = {
      chainId: resBlock.header.chain_id,
      height: resBlock.header.height,
      hash: resBlock.block_id,
      time: resBlock?.header.time,
      proposer: resBlock.header.proposer_address,
    };

    setBlock(block);
  };

  useEffect(() => {
    getTranDetail();
  }, [tx]);

  function generateBlockDetailPath(template, height) {
    let path = template;
    path = path.replace(`:height`, height);
    return path;
  }

  return (
    <section id="transaction">
      <Title title="Transaction detail" />
      {loading ? (
        <>
          <div className="spin-wrapper flex justify-center my-2">
            <CircularProgress />
          </div>
        </>
      ) : !tranDetail.blockId ? (
        <>Data not found</>
      ) : (
        <>
          <div className="w-full p-6">
            <ul className="flex flex-col gap-1">
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Chain Id:</span>
                {block?.chainId}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Tx Hash:</span>
                {tranDetail?.hash}
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Status:</span>
                <p
                  className={
                    tranDetail?.returnCode === 0 ||
                    tranDetail?.txType === "Wrapper"
                      ? "text-green-500"
                      : "text-red-500"
                  }
                >
                  {tranDetail?.returnCode === 0 ||
                  tranDetail?.txType === "Wrapper"
                    ? "Success"
                    : "Fail"}
                </p>
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Height:</span>
                <Link
                  className="link"
                  to={generateBlockDetailPath(
                    config.routes.blockDetail,
                    block?.height
                  )}
                >
                  {block?.height}
                </Link>
              </li>
              <li className="aboutRightLi">
                <span className="aboutRightLiSpan">Time:</span>
                {block?.time}
              </li>
            </ul>
          </div>
          <RawData data={tranDetail} />
        </>
      )}
    </section>
  );
};

export default TransactionDetail;
